import React from "react";
import './episodes.css'
import {DefaultPlayer as Video} from 'react-html5video'
import 'react-html5video/dist/styles.css'
import Vido from '../assets/videos/e12k.mp4'
import phone from '../assets/videos/e12k-iphone.mp4'





const Episodes = () => {
    return (

        <div className="e-container">
            
            <text className="titless">Episodes</text>

            <div className="episode-list">

                <div className="e-1">
                    <Video 
                    autoplay
                    loop
                    className='vid'
                    >
                        <source src={Vido} type="video/mp4"/>
                    </Video>

                    <div className="words">NBA 2k on Randy.tv____ep.1</div>


                    <Video 
                    autoplay
                    loop
                    className='iphone'
                    >
                        <source src={phone} type="video/mp4"/>
                    </Video>


                </div>



            </div>
        </div>

    )
}

export default Episodes;