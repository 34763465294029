import React from "react";
import Episodes from "./components/episodes";
import Logo from "./components/logo";
import Navbar from "./components/navbar";
import Footer from './components/footer';


function App() {
  return (
    <>
    <Navbar/>
    <Logo/>
    <Episodes/>
    <Footer/>
    </>
  );
}

export default App;
